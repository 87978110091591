import React, { useEffect, useState } from "react"
import { Layout } from 'antd';
import axios from "axios";
import { toast } from "react-nextjs-toast";
import HeaderLanding from "../../Layout/header";
import ThemeImages from "../../themes/appImage";
import Slider from "react-slick";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Formik } from 'formik';
import * as Yup from 'yup';

const { Header, Footer, Sider, Content } = Layout;

const Home = (props) => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    useEffect(() => {
        document.title = 'SMACK’EM SPADES';
        window.history.scrollRestoration = 'manual'
        window.scrollTo(0, 0)

    }, [])

    const [toogled, setToogle] = useState(false);

    const handletoogle = () => {
        setToogle(!toogled)
    }

    const [isVisible, setIsVisible] = useState(false);
    const [sticky, setsticky] = useState(false);
    const [loader, setLoader] = useState(false)


    // Show button when page is scorlled upto given distance
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const addclass = () => {
        if (window.pageYOffset > 20) {
            setsticky(true);
        } else {
            setsticky(false);
        }
    };

    // Set the top cordinate to 0
    // make scrolling smooth
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        window.addEventListener("scroll", addclass);
    }, []);

    // validation yup schema
    const validationSchema = Yup.object({
        firstName: Yup.string()
            .min(3, 'First name should be at least 3 characters long.')
            .required('Please enter first name.'),
        lastName: Yup.string()
            .min(3, 'Last name should be at least 3 characters long.')
            .required('Please enter last name.'),
        subject: Yup.string()
            .min(3, 'Subject should be at least 3 characters long.')
            .required('Please enter subject.'),
        message: Yup.string()
            .min(3, 'Message should be at least 3 characters long.')
            .required('Please enter message.'),
        email: Yup.string()
            .email("Please enter valid email address.")
            .required("Please enter email address.")
            
            .matches(
                /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,4})+$/,
                "Please enter valid email address."
            )
            
           

    })



    const makeGetRequest = async (payload, resetForm) => {

        const headers = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'accesstoken': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MjMyZDY0ZTY1N2MzNTMxZDA1MDUxMzgiLCJkZXZpY2VUb2tlbiI6IjYxMDg5MmZmODFiMDU2YmIwNGI2ZWJjMWI1MTdiZTRiYmZkYWI1NWEiLCJpYXQiOjE2NDc0OTg4MzB9.JcV6Xzx_UeWvFFSA5FGZ5fCFDSQ-WCQ9D0WZDw4Mkl4'
        }

        // const config = { 
        //     headers: {'accesstoken ': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MjMyZDY0ZTY1N2MzNTMxZDA1MDUxMzgiLCJkZXZpY2VUb2tlbiI6IjYxMDg5MmZmODFiMDU2YmIwNGI2ZWJjMWI1MTdiZTRiYmZkYWI1NWEiLCJpYXQiOjE2NDc0OTg4MzB9.JcV6Xzx_UeWvFFSA5FGZ5fCFDSQ-WCQ9D0WZDw4Mkl4'}
        // }
        try {
            let response = await axios.post('http://api.smackemspades.com/v1/api/user/contactUs', payload, { headers: headers });
            const { data, status } = response
            if (status === 200) {
                // Formik.
                
                resetForm()
                setLoader(false)
                // if (data.message == 'Your message has been sent successfully.') {
                    toast.notify(data.message, {
                        duration: 5,
                        type: "success",
                    });
                // }
            }

        } catch (error) {
            setLoader(false)
            toast.notify('Something went wrong', {
                duration: 5,
                type: "error",
            });
            console.log(error);
        }



    }

    //   validation schema ends
    const handleFormSubmit = (values, { resetForm }) => {
        console.log('on Submit hit ------', values, resetForm);
        // resetForm()
        setLoader(true)
        makeGetRequest(values, resetForm)
        // dispatch(loginAction(values, history))

    }



    const handleAddWordChange = (data, name, updateValue) => {
        let value = data.target.value
        let reg = /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,8})+$/
        // if (_checkSpecialChar(value)) {
        //   data.preventDefault()
        // } else {
        if (value === " ") {
            updateValue(name, "")
        } else {
            updateValue(name, value.trimLeft())
        }
        // }
    }


    return (
        <div>
            <HeaderLanding handle={handletoogle} toogled={toogled} sticky={sticky} />
            <section className="slider">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 slideInRight  wow delay-1">
                            <div className="Smackem-details">
                                <h2>SMACK’EM SPADES</h2>
                                <p>
                                Broken Shackles Entertainment (B.S.E) - proudly introduces a new and exciting way of playing a card game we all love.
                                </p>
                                <p>
                                B.S.E. creators have successfully reinvented the game of Spades, with enhanced features that provide an adrenaline 
                                rush of excitement and appeals to extreme competitors across the globe. Our new interactive version of Spades gives 
                                each player a heart pounding experience, full of fun, and is a great activity to be enjoyed among family and friends.
                                 For centuries, Spades playing has been a favorite past time activity, uniting various people of all sorts from all over the world.
                                  B.S.E. presents Smack 'em Spades, a one of a kind experience; so no matter where you are, you can feel right at home with our new 
                                   mobile version of a card game loved by so many.
                                </p>
                                <div className="common_btn" style={{ visibility: 'hidden' }}>
                                    <button type="button" className="btn btn-primary">Learn More</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-5 text-center" style={{ display: 'none' }}>
                        </div>
                    </div>
                </div>
                <div className="Mobile_1 slideInRight wow cards">
                    <img src={ThemeImages.cards} />
                </div>
            </section>
            <section class="screenshot" >
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <h2 class="heading  slideInUp wow" style={{ marginTop: '28px' }}>Screenshots</h2>
                            <Slider {...settings}>
                                <div>
                                    <img src={ThemeImages.slider1} />
                                </div>
                                <div>
                                    <img src={ThemeImages.slider2} />
                                </div>
                                <div>
                                    <img src={ThemeImages.slider3} />
                                </div>
                                <div>
                                    <img src={ThemeImages.slider4} />
                                </div>
                                <div>
                                    <img src={ThemeImages.slider5} />
                                </div>
                            </Slider>

                        </div>
                    </div>
                </div>
            </section>

            <section className="clean_interface">
                <div className="container">
                    <div className="row align-item-center">
                        <h2 className="heading  slideInUp wow" style={{ marginTop: '50px' }}>Get the app on your phone</h2>
                        <div className="d-flex w-100 justify-content-center appss">
                            <img src={ThemeImages.appstore} alt="app store" className="store-img slideInRight  wow" style={{ marginRight: '22px' }} />
                            <img src={ThemeImages.playstore} alt="play store" className="store-img slideInLeft  wow" />
                        </div>
                        <div className="text-center slideInDown  wow w-100" id="container">
                            <div className="mobile_circle">
                                {console.log("ThemeImages = ", ThemeImages)}
                                <img src={ThemeImages.moblie} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about_us" id="aboutus" >
                <div className="container">
                    <div className="row align-item-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="slideInRight  wow" id="container">
                                <div className="mobile_circle">
                                    <img src={ThemeImages.about} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 col-sm-12 col-12  slideInLeft wow">
                            <h2 className="text-left heading" style={{ marginTop: '28px' }}>About Us</h2>
                            <p>
                            Broken Shackles Entertainment is a newly marketed media production with a variety of upcoming projects. We are partnered with some of the most  experienced and extraordinary content creators from all over the world to give you the best online gaming production entertainment has to offer. 
                            </p>
                            <p>Located in Portland Oregon; we are always looking to expand our  business operation. With achievable goals; our brand is our priority. B.S.E is currently laying the ground work to excel alongside other existing media platforms and outlets. We thank you for being a part of this journey and sharing in this experience.</p>
                            <div className="common_btn" style={{ marginTop: '30px', visibility: 'hidden' }}>
                                <button type="button" className="btn btn-primary">Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact_us" id="contactus">
                <div className="container">
                    <div className="row align-item-center slideInDown wow">
                        <h2 className="heading  slideInUp wow" style={{ marginTop: '50px', marginBottom: '20px' }}>Contact Us</h2>
                        <Formik
                            enableReinitialize
                            initialValues={{ email: '', firstName: '', lastName: '', subject: '', message: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        // onReset={}
                        >
                            {({
                                values,
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                setFieldValue
                            }) => (
                                <form className="form-center" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>First Name</label>
                                            <div className="form-group">
                                                <input type="text"
                                                    class="form-control"
                                                    id="firstName"
                                                    name="firstName"
                                                    maxLength={35}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    // onKeyDown={(e) => {
                                                    //     if (e.key === " ") {
                                                    //         e.preventDefault();
                                                    //     }
                                                    // }}

                                                    value={values.firstName.trimLeft()}
                                                    placeholder="First Name"
                                                    autoComplete="off"
                                                />
                                                {touched.firstName && errors.firstName ? (
                                                    <div class='color-error'>{errors.firstName}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Last Name</label>
                                            <div className="form-group">
                                                <input type="text"
                                                    class="form-control"
                                                    id="lastName"
                                                    name="lastName"
                                                    // onKeyDown={(e) => {
                                                    //     if (e.key === " ") {
                                                    //         e.preventDefault();
                                                    //     }
                                                    // }}
                                                    maxLength={35}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.lastName.trimLeft()}
                                                    placeholder="Last Name"
                                                    autoComplete="off"
                                                />
                                                {touched.lastName && errors.lastName ? (
                                                    <div class='color-error'>{errors.lastName}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>Email Address</label>
                                            <div className="form-group">
                                                <input type="text"
                                                    class="form-control"
                                                    id="email"
                                                    name="email"
                                                    onKeyDown={(e) => {
                                                        if (e.key === " ") {
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email.trimLeft()}
                                                    placeholder="Email Address"
                                                />
                                                {touched.email && errors.email ? (
                                                    <div class='color-error'>{errors.email}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>Subject</label>
                                            <div className="form-group">
                                                <input type="text"
                                                    class="form-control"
                                                    id="subject"
                                                    name="subject"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.subject.trimLeft()}
                                                    placeholder="Subject"
                                                    maxLength={100}
                                                />
                                                {touched.subject && errors.subject ? (
                                                    <div class='color-error'>{errors.subject}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Message</label>
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Message"
                                                    id="message"
                                                    name="message"
                                                    onChange={(e) => {
                                                        handleAddWordChange(e, 'message', setFieldValue)
                                                    }}
                                                    onBlur={handleBlur}
                                                    maxLength={500}
                                                    value={values.message}

                                                >
                                                </textarea>
                                                {touched.message && errors.message ? (
                                                    <div class='color-error'>{errors.message}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="common_btn text-center mb-5" style={{ marginTop: '30px' }}>
                                        <button type="submit" className="btn btn-primary" disabled={loader}>Submit</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </section>

            <section class="foter_bottom">
                <div class="container">
                    <div class="footer-bottom">
                        <div class="row">
                            <div class="col-sm-4 text-left">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li>
                                    <a  href="#contactus">Contact Us</a>
                                    </li>
                                    <li>
                                    <a  href="#aboutus">About Us</a>
                                    </li>
                                    <li>
                                        <a href="privacy" style={{ cursor: 'default' }}>
                                        Privacy 
                                        </a>
                                    </li>

                                    <li>
                                        <a href="terms" style={{ cursor: 'default'  }}>
                                        Terms and conditions 
                                        </a>
                                    </li>
                                    <li>
                                        <a href="cookiepolicy" style={{ cursor: 'default' }}>
                                        Cookie policy 
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                            <div class="col-sm-4 text-center">
                                <a href="javascript:void(0);" style={{ cursor: 'default' }}>
                                    <img src={ThemeImages.logo} class="footer_logo" />
                                </a>
                                <h5>Follow Us On</h5>
                                <div class="social_links">
                                    <a href="javascript:void(0);" data-toggle="tooltip" data-placement="bottom" title="Facebook" class="tooltip_clr">
                                        <img src={ThemeImages.facebook} alt="" class="fb_icon mr-2" />
                                    </a>
                                    <a href="javascript:void(0);" data-toggle="tooltip" data-placement="bottom" title="Twitter" class="tooltip_clr">
                                        <img src={ThemeImages.twitter} alt="" class="tw-icon mr-2" />
                                    </a>
                                    <a href="javascript:void(0);" data-toggle="tooltip" data-placement="bottom" title="Instagram" class="tooltip_clr">
                                        <img src={ThemeImages.instagram} alt="" class="insta_icon" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-sm-4 text-right">
                                <h4 class="reach">Reach Us At</h4>
                                <ul>
                                    <li>
                                        <a href="/" style={{ cursor: 'default' }}>
                                            www.smackemspades.com
                                        </a>
                                    </li>
                                   

                                   
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section class="foter_bottom2">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 copyright-ani">
                            <p > &copy; 2022 Smack’em Spades. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </section>


            {isVisible &&
                <div onClick={scrollToTop}>
                    <a href="#" class="scrollup"></a></div>}

        </div>
    )
}

export default Home