import React, { useEffect } from "react"
import { Layout, Menu } from 'antd';
import images from "../themes/appImage";
import { useLocation } from "react-router"
import * as Scroll from 'react-scroll';


import {
  useNavigate

} from "react-router-dom";
import { Link } from 'react-scroll'
const { Header, Footer, Sider, Content } = Layout;

const HeaderLanding = (props) => {

  console.log('this is to toggle', props)
  useEffect(() => {
    document.title = 'SMACK’EM SPADES';
    window.scrollTo(0, 0)

  }, [])
  

  return (
  <header className= "headers" style={{backgroundColor: props.sticky ? 'rgba(0, 0, 0, 0.8)': 'transparent'}}>
      <div class="container">
        <nav class="navbar navbar-expand-lg">
          <a class="navbar-brand" href="/" >
            <img src={images.logo} alt="" />  </a>

          <button class="navbar-toggler" onClick={() => props.handle()} type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class={props.toogled ? 'collapse navbar-collapse  justify-content-end show':'collapse navbar-collapse  justify-content-end '} id="collapsibleNavbar">
            {
              props.data !== 'contain' ? 
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link active" href="#">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#aboutus">About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#contactus">Contact Us</a>
              </li>
            </ul>:''
}
          </div>
        </nav>
      </div>
    </header>
  )
}
export default HeaderLanding