import logo from './logo.svg';
import './App.css';
import Home from './Components/home/index'
import '../src/css/style.css'
import '../src/css/responsive.css'
import '../src/css/bootstrap.css'
import { ToastContainer } from "react-nextjs-toast";
// import '../src/css/animate.css'
import 'antd/dist/antd.css';
import PrivacyPolicy from './Components/home/privacyPolicy';
import RoutesNew from './Route/Routes';
function App() {
  return (
    <div className="">
        <ToastContainer align={"center"} position={"bottom"} />
        <RoutesNew/>

     {/* <Home /> */}
     {/* <PrivacyPolicy/> */}

    

    </div>
    
  );
}

export default App;
