import React from "react"
import Home from "../Components/home"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import PrivacyPolicy from "../Components/home/privacyPolicy"
import TermsCondition from "../Components/termConditions";
import CookiePolicy from "../Components/cookiepolicy";

const RoutesNew = () => {


    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/cookiepolicy" element={<CookiePolicy />} />
                <Route path="/terms" element={<TermsCondition />} />
            </Routes>
        </Router>

    )
}
export default RoutesNew