const ThemeImages = {
    logo: require('../images/logo.png'),
    cards: require('../images/cards.png'),
    slider1: require('../images/slider1.png'),
    slider2: require('../images/slider2.png'),
    slider3: require('../images/slider3.png'),
    slider4: require('../images/slider4.png'),
    slider5: require('../images/slider5.png'),
    playstore: require('../images/play store.png'),
    appstore: require('../images/app store.png'),
    moblie: require('../images/get-app-mokup1.png') ,
    about: require('../images/aboutus-mokup.png') ,
    facebook: require('../images/facebook 1.png') ,
    twitter: require('../images/twitter 1.png') ,
    instagram: require('../images/instagram 1.png') ,
    
}

    export default ThemeImages;